.banners-overflow-container {
  position: relative;
  overflow: hidden;
}

.banners-overflow-container .swiper {
  overflow: visible !important; /* remove swiper container overflow */
}

.banners-container {
  position: relative;
  margin: auto;
  width: calc(100% - 48px);
}

.banners-container .swiper {
  margin-left: -16px !important;
}

.banners-container .swiper-slide-active {
  margin-left: 8px;
  transition: width 0.6s ease;
}

@media only screen and (min-width: 980px) {
  .banners-container {
    width: calc(100% - 24px);
    margin-right: 24px;
  }

  .swiper-slide-active .banner-item {
    padding-left: 104px;
  }

  .swiper-slide-active .banner-item-content {
    width: 400px;
    padding-left: 0px;
  }

  .swiper-slide-active .banner-item-one {
    width: 100% !important;
  }

  .banners-container .swiper-slide:not(.swiper-slide-next, .swiper-slide-prev) {
    margin-left: 16px;
    margin-right: 0 !important;
  }

  .banners-container .swiper-slide-active:has(+ .swiper-slide) {
    margin-right: 16px !important;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1999px) {
  .banners-container .swiper-slide-active {
    width: calc(70% - 16px) !important;
    margin-left: 16px;
  }

  .banners-container .swiper-slide-next {
    max-width: calc(30% - 16px) !important;
  }

  .swiper-slide-active img {
    display: block;
  }

  #banner > div:not(.swiper) img {
    display: block;
  }
}

@media only screen and (min-width: 2000px) {
  .banners-container .swiper-slide-active {
    width: calc(50% - 16px) !important;
    margin-left: 16px;
  }

  .banners-container .swiper-slide-next {
    max-width: calc(50% - 16px) !important;
  }

  .banners-container .swiper-slide img {
    display: block;
  }
}

/* Pagination */

.banners-container .swiper-pagination-bullets {
  @media only screen and (max-width: 980px) {
    display: none;
  }
}

.banners-container .swiper-pagination-bullet-active {
  width: 70px;
  height: 6px;
  border-radius: 3px;
  background-color: #f6f7f9 !important;
  box-shadow: white;
  opacity: 1;
  transition: width 0.6s ease;
}

.banners-container button:last-child.swiper-pagination-bullet-active {
  transition: width 0.6s ease-in-out;
}

.custom-bullet-0.swiper-pagination-bullet-active ~ .swiper-pagination-bullet {
  visibility: hidden;
}

.custom-bullet-0.swiper-pagination-bullet-active + .custom-bullet-1 {
  visibility: visible;
}

.custom-bullet-1.swiper-pagination-bullet-active ~ .swiper-pagination-bullet {
  visibility: hidden;
}

.custom-bullet-0:not(.swiper-pagination-bullet-active)
  + .custom-bullet-1:not(.swiper-pagination-bullet-active) {
  visibility: hidden;
}

.custom-bullet-0:not(.swiper-pagination-bullet-active):has(
    + .swiper-pagination-bullet:not(.swiper-pagination-bullet-active)
  ) {
  visibility: hidden;
}

#containerForBullets:has(.custom-bullet-2.swiper-pagination-bullet-active),
#containerForBullets:has(.custom-bullet-3.swiper-pagination-bullet-active) {
  margin-left: -16px;
}

.banners-hidden {
  opacity: 0;
  transform: translateY(10px);
}

.banners-fade-in {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.banners-not-ready {
  height: 376px;
  display: flex;
  flex-direction: row;
  padding-right: 16px;
  padding-bottom: 16px;
  gap: 16px;
}

.banners-not-ready__first-banner {
  width: 70%;
}

.banners-not-ready__first-banner .banner-item {
  padding-left: 104px;
}

.banners-not-ready__second-banner {
  width: 30%;
}
